<template>
  <div class="error-page fill-height d-flex flex-column align-center">
    <div class="content-container d-flex align-start">
      <v-icon color="#a0c9e7">mdi-alert-circle</v-icon>

      <div class="content-container__message">
        <div class="text-h4">{{ errorMessage }}</div>

        <div class="mt-14 d-flex">
          <v-btn color="secondary" @click="$router.back()">
            {{ $t('errorPage.goBack') }}
          </v-btn>

          <v-btn class="ml-3" color="primary" to="/">
            {{ $t('errorPage.goToMainPage') }}
          </v-btn>
        </div>
      </div>
    </div>

    <v-spacer />

    <banners-place class="mt-10 mb-5" :place-type="BannerPlaceType.ERROR_PAGE_ADVERTISING" load />
  </div>
</template>

<script>
  import { BannerPlaceType } from '@/enums/aquarius'
  import BannersPlace from '@/components/BannersPlace'

  export default {
    name: 'ErrorPageView',
    components: { BannersPlace },

    props: {
      errorCode: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        BannerPlaceType
      }
    },

    computed: {
      errorMessage() {
        const trKey = `errorPage.codes.${this.errorCode}`
        return this.$t(this.$te(trKey) ? trKey : 'errorPage.unknownError')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .error-page {
    .content-container {
      margin-top: 30vh;

      .v-icon {
        font-size: 90px;
        margin-right: 76px;
      }

      .content-container__message {
        flex-basis: 500px;

        .v-btn {
          flex-grow: 1;
          flex-basis: 0;
        }
      }
    }
  }
</style>
